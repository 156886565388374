import React from "react";
import logo from "../assets/Logo-Design.png";

const SingleLogo = () => {
  return (
    <div className="grid h-screen bg-green-100 place-items-center">
      <img src={logo} className="h-96" alt="DJ Tech Inc Logo"></img>
    </div>
  );
};

export default SingleLogo;
